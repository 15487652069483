import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return !window.location.pathname.includes("cardanotrees") && (
            <footer className="footer">
                <div className="container footer-text">
                    <p>Copyright &copy; 2016-2025 Christian Mahler</p>
                </div>
            </footer>
        );
    }
}

export default Footer;
